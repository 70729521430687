/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IconAWS,
  IconDeepSeek,
  IconFlask,
  IconLightning,
  IconLightningFilled,
  IconClarifai,
  IconLlama,
  IconMistral,
  IconOpenAI,
  IconPerplexity,
  IconVerticalSlider,
  IconAlert,
  IconGCP,
  IconQwen,
  IconMicrosoft,
  IconOpenbmb,
} from 'components/Icons';

const iconsConfig = [
  {
    id: 'IconAWS',
    component: IconAWS,
  },
  {
    id: 'IconDeepSeek',
    component: IconDeepSeek,
  },
  {
    id: 'IconFlask',
    component: IconFlask,
  },
  {
    id: 'IconLightning',
    component: IconLightning,
  },
  {
    id: 'IconLightningFilled',
    component: IconLightningFilled,
  },
  {
    id: 'IconClarifai',
    component: IconClarifai,
  },
  {
    id: 'IconLlama',
    component: IconLlama,
  },
  {
    id: 'IconMistral',
    component: IconMistral,
  },
  {
    id: 'IconOpenAI',
    component: IconOpenAI,
  },
  {
    id: 'IconPerplexity',
    component: IconPerplexity,
  },
  {
    id: 'IconVerticalSlider',
    component: IconVerticalSlider,
  },
  {
    id: 'IconAlert',
    component: IconAlert,
  },
  {
    id: 'IconGCP',
    component: IconGCP,
  },
  {
    id: 'IconQwen',
    component: IconQwen,
  },
  {
    id: 'IconMicrosoft',
    component: IconMicrosoft,
  },
  {
    id: 'IconOpenbmb',
    component: IconOpenbmb,
  },
];

const offlineConfig = [
  {
    id: 'general-image-recognition',
    iconComponent: 'IconClarifai',
  },
  {
    id: 'MiniCPM-o-2_6-language',
    iconComponent: 'IconOpenbmb',
  },
  {
    id: 'Qwen2_5-VL-7B-Instruct',
    iconComponent: 'IconQwen',
  },
  {
    id: 'Qwen2_5-Coder-7B-Instruct-vllm',
    iconComponent: 'IconQwen',
  },
  {
    id: 'phi-4',
    iconComponent: 'IconMicrosoft',
  },
  {
    id: 'MiniCPM3-4B',
    iconComponent: 'IconOpenbmb',
  },
  {
    id: 'QwQ-32B-AWQ',
    iconComponent: 'IconQwen',
  },
  {
    id: 'phi-4-mini-instruct',
    iconComponent: 'IconMicrosoft',
  },
  {
    id: 'Llama-3_2-3B-Instruct',
    iconComponent: 'IconLlama',
  },
  {
    id: 'gemma-3-4b-it',
    iconComponent: 'IconGCP',
  },
  {
    id: 'DeepSeek-R1-Distill-Qwen-7B',
    iconComponent: 'IconDeepSeek',
  },
  {
    id: 'gpt-4o',
    iconComponent: 'IconOpenAI',
  },
  {
    id: 'claude-3_5-sonnet',
    iconComponent: 'IconClarifai',
  },
  {
    id: 'llama-3_2-11b-vision-instruct',
    iconComponent: 'IconLlama',
  },
  {
    id: 'pixtral-12b',
    iconComponent: 'IconFlask',
  },
  {
    id: 'got-ocr-2_0',
    iconComponent: 'IconClarifai',
  },
];

export const mergeCardsWithConfigAndIcons = (cards: any[], config: any[] = offlineConfig): any[] => {
  const configMap = new Map(config.map((item) => [item.id, item]));

  const iconsMap = new Map(iconsConfig.map((icon) => [icon.id, icon.component]));

  return cards.map((card) => {
    const replacement = configMap.get(card.id);

    if (replacement) {
      const { iconComponent: iconId, ...replacementWithoutIcon } = replacement;

      const mergedCard = { ...card, ...replacementWithoutIcon };

      if (iconId) {
        const iconComponent = iconsMap.get(iconId);
        if (iconComponent) {
          mergedCard.iconComponent = iconComponent;
        }
      }

      return mergedCard;
    }

    return card;
  });
};
