import { css } from '@linaria/core';
import { breakpoints, flex } from 'styles/utils';
export const headerAnimatedBackground = "odules__Featured__components__Header__NewHeader_styles_ts-headerAnimatedBackground-h11sk2o";
export const headerWrapper = `${"odules__Featured__components__Header__NewHeader_styles_ts-headerWrapper-h1iy9gg0"}
  ${flex.init}
  ${flex.direction.column}
  ${flex.alignItems.center}
  ${flex.justifyContent.center}
`;
const graphImage = "odules__Featured__components__Header__NewHeader_styles_ts-graphImage-g1t83yov";
export const graphImageLeft = `${"odules__Featured__components__Header__NewHeader_styles_ts-graphImageLeft-gppn36r"}
  ${graphImage}
`;
export const graphImageRight = `${"odules__Featured__components__Header__NewHeader_styles_ts-graphImageRight-g193jdsr"}
${graphImage}
`;

require("./NewHeader.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./NewHeader.styles.ts");