import { useRouter } from 'next/router';
import { either } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { FC } from 'react';
import { reactLeftToJSX, UI_STATES } from 'utils/uiStates/uiStates';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { modelsMST } from 'modules/Models/listingStore/mst-types';
import { Card } from 'modules/Home/cards/Card';
import { StarComponent } from 'components/List/components/Star/Star';
import { SupportedListingEntities } from 'modules/Listings/types';
import { flex } from 'styles/utils';
import { cx } from '@linaria/core';
import { IconArrowRight, IconClarifai } from 'components/Icons';
import { v4 as uuidv4 } from 'uuid';
import { HorizontalLayout } from 'modules/Home/layout/HorizontalLayout';
import { featuredApiEffects } from 'modules/Featured/components/FeaturedItems/apiEffects';
import { toJS } from 'mobx';
import { mergeCardsWithConfigAndIcons } from 'utils/exploreConfigMerge';
import { wrapper, headingContainer, heading, starBadge, cardCls } from 'modules/NewFeaturedModels/styles';
import { FeaturedModel } from 'modules/NewFeaturedModels/types';
import { clickOnViewDetails, isFeaturedModel } from 'modules/NewFeaturedModels/utils';

export const NewFeaturedModels: FC = () => {
  const { data: featuredModels } = useQueryTE([queryKeys.Models, { featured: isFeaturedModel }], featuredApiEffects.listFeaturedModelsTE());
  const router = useRouter();

  return (
    <div className={wrapper}>
      <div className={headingContainer}>
        <h2 className={heading}>🔥 Trending Models</h2>
      </div>
      <HorizontalLayout>
        {pipe(
          featuredModels,
          either.mapLeft((_) => ({ type: UI_STATES.empty })),
          either.fold(reactLeftToJSX(), (featuredModelsList) => {
            const models = modelsMST.create(featuredModelsList);

            // TODO: pass config from launchdarkly (as a second param to the fn below)
            const newModels: FeaturedModel[] = mergeCardsWithConfigAndIcons(toJS(models));

            return (
              <>
                {newModels.map((model) => (
                  <Card
                    key={uuidv4()}
                    icon={model.iconComponent ? <model.iconComponent size={40} /> : <IconClarifai size={40} />}
                    title={model.name}
                    body={model.description}
                    cardClassName={cardCls}
                    badge={<StarComponent entity={model as SupportedListingEntities} data-testid="app-star-btn" />}
                    badgeClassName={starBadge}
                    footer={
                      <button
                        className={cx(flex.init, flex.gap.half, flex.justifyContent.center, flex.alignItems.center, 'link', 'details-link')}
                        onClick={() => clickOnViewDetails(router, model)}
                        type="button"
                      >
                        VIEW DETAILS
                        <IconArrowRight size={16} />
                      </button>
                    }
                  />
                ))}
              </>
            );
          }),
        )}
      </HorizontalLayout>
    </div>
  );
};
