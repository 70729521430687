import { FeaturedModel, PredictableModelType } from 'modules/NewFeaturedModels/types';
import { NextRouter } from 'next/router';

export const isFeaturedModel = true;

export const navigateToModelPage = (router: NextRouter, user_id: string, app_id: string, model_id: string) => {
  router.push({
    pathname: `/${user_id}/${app_id}/models/${model_id}`,
  });
};

export const navigateToPlayground = (router: NextRouter, model: string) => {
  router.push({
    pathname: '/playground',
    query: { model },
  });
};

const modelsRelatedToPlayground = [PredictableModelType.MULTIMODAL_TO_TEXT, PredictableModelType.TEXT_TO_TEXT];

export const shouldGoToPlaygroundPage = (modelTypeId: PredictableModelType) => {
  return modelsRelatedToPlayground.includes(modelTypeId);
};

export const clickOnViewDetails = (router: NextRouter, model: FeaturedModel) => {
  if (isFeaturedModel && shouldGoToPlaygroundPage(model.model_type_id)) {
    navigateToPlayground(router, model.id);
  } else {
    navigateToModelPage(router, model.user_id, model.app_id, model.id);
  }
};
