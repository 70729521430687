import { FC, useCallback, useMemo, useState } from 'react';
import { cx } from '@linaria/core';
import { either } from 'fp-ts';
import { toJS } from 'mobx';
import { pipe } from 'fp-ts/lib/function';
import { modelsMST } from 'modules/Models/listingStore/mst-types';
import { reactLeftToJSX, UI_STATES } from 'utils/uiStates/uiStates';
import { flex } from 'styles/utils';
import { Card } from 'modules/Home/cards/Card';
import { v4 as uuidv4 } from 'uuid';
import { IconArrowRight, IconChevronRight, IconClarifai } from 'components/Icons';
import { StarComponent } from 'components/List/components/Star/Star';
import { SupportedListingEntities } from 'modules/Listings/types';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { IconButton } from 'components/Button/IconButton';
import { featuredApiEffects } from 'modules/Featured/components/FeaturedItems/apiEffects';
import { Popover, PopoverGroup, PopoverAction, SegmentTitle, usePopoverState } from 'components/Popover';
import { SorterItems } from 'modules/Listings/components/Sorter/SorterItems';
import { IconSortDown } from 'components/Icons/IconSortDown';
import { HorizontalLayout } from 'modules/Home/layout/HorizontalLayout';
import { mergeCardsWithConfigAndIcons } from 'utils/exploreConfigMerge';
import {
  wrapper,
  headingContainer,
  heading,
  chevronIcon,
  chevronIconButton,
  chevronIconTooltip,
  sorterWrapper,
  starBadge,
  cardCls,
  loadMoreContainer,
} from 'modules/NewFeaturedModelsWithFilters/styles';
import { clickOnViewDetails, isFeaturedModel } from 'modules/NewFeaturedModels/utils';
import { useRouter } from 'next/router';
import { Button } from 'components/Button';

type Option<T> = {
  name?: string;
  value: T;
};

export const appSortCriteria = [
  {
    value: 'sort_by_star_count',
    name: 'Star Count',
  },
  {
    value: 'sort_by_modified_at',
    name: 'Last Updated',
  },
  {
    value: 'sort_by_created_at',
    name: 'Last Created',
  },
  {
    value: 'sort_by_name',
    name: 'App ID',
  },
];

export const NewFeaturedModelsWithFilters: FC = () => {
  const { data: featuredModels } = useQueryTE([queryKeys.Models, { featured: isFeaturedModel }], featuredApiEffects.listFeaturedModelsTE());
  //   const { data: featuredWorkflows } = useQueryTE([queryKeys.Workflows, { featured: true }], featuredApiEffects.listFeaturedWorkflowsTE());
  //   const { data: featuredModels } = useQueryTE([queryKeys.Models, { featured: true }], featuredApiEffects.listFeaturedModelsTE());
  // const { data: featuredModules } = useQueryTE([queryKeys.Modules, { featured: true }], featuredApiEffects.listFeaturedModulesTE());

  const router = useRouter();

  const popover = usePopoverState({ placement: 'bottom-end' });
  const [sortBy, setSortBy] = useState<Option<string>>(appSortCriteria[0]);

  const sortByHandler = useCallback(
    (x: Option<string>) => {
      setSortBy(x);
      popover.hide();
    },
    [setSortBy],
  );

  const sortByLabel = useMemo(() => appSortCriteria.find(({ value }) => value === sortBy.value)?.name, [sortBy.value]);

  const clickOnLoadMore = () => {
    router.push({
      pathname: '/explore/models',
      query: {
        filterData: JSON.stringify([{ field: 'use_cases', value: ['llm'] }]),
        page: '1',
        perPage: '24',
      },
    });
  };

  return (
    <div className={wrapper}>
      <div className={headingContainer}>
        <h2 className={heading}>Large Language Models</h2>
        <IconButton
          Icon={IconChevronRight}
          className={chevronIconButton}
          iconClassName={chevronIcon}
          aria-label="autocomplete-button"
          size={16}
          onClick={() => {}}
          variant="link"
          tooltipClassName={chevronIconTooltip}
        />
        <div className={sorterWrapper}>
          <PopoverAction
            popover={popover}
            style={{
              flex: 1,
              display: 'flex',
            }}
          >
            <div className={cx('title', 'container')}>
              <span className="sortByLabel">{sortByLabel}</span>
              <IconSortDown size={8} color="#808080" />
            </div>
          </PopoverAction>
          <Popover popover={popover} aria-label="Sort By">
            <PopoverGroup style={{ borderTop: 0, paddingTop: 0 }}>
              <SegmentTitle>SORT BY</SegmentTitle>
              <SorterItems
                items={appSortCriteria}
                selectedValue={sortBy.value}
                // https://clarifai.atlassian.net/browse/MRK-1140
                // assigning to Prasanna due to lack of typing using generics in SorterItems
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onChange={sortByHandler as any}
              />
            </PopoverGroup>
          </Popover>
        </div>
      </div>
      <HorizontalLayout>
        {pipe(
          featuredModels,
          either.mapLeft((_) => ({ type: UI_STATES.empty })),
          either.fold(reactLeftToJSX(), (featuredModelsList) => {
            const models = modelsMST.create(featuredModelsList);

            // TODO: pass config from launchdarkly (as a second param to the fn below)
            const newModels = mergeCardsWithConfigAndIcons(toJS(models));
            return (
              <>
                {newModels.map((model) => (
                  <Card
                    key={uuidv4()}
                    icon={model.iconComponent ? <model.iconComponent size={40} /> : <IconClarifai size={40} />}
                    title={model.name}
                    body={model.description}
                    cardClassName={cardCls}
                    badge={<StarComponent entity={model as SupportedListingEntities} data-testid="app-star-btn" />}
                    badgeClassName={starBadge}
                    footer={
                      <button
                        className={cx(flex.init, flex.gap.half, flex.justifyContent.center, flex.alignItems.center, 'link', 'details-link')}
                        onClick={() => clickOnViewDetails(router, model)}
                        type="button"
                      >
                        VIEW DETAILS
                        <IconArrowRight size={16} />
                      </button>
                    }
                  />
                ))}
              </>
            );
          }),
        )}
      </HorizontalLayout>
      <div className={loadMoreContainer}>
        <Button variant="tertiary" onClick={clickOnLoadMore} size="md" className="load-more-btn">
          Load More Large Language Models
        </Button>
      </div>
    </div>
  );
};
