import { cx } from '@linaria/core';
import { bigH1, regularText } from 'styles/typography';
import { wrapper, H1Styles, PStyles } from './NewHeading.styles';

export const NewHeading: React.FC = () => {
  return (
    <div className={wrapper}>
      <h1 className={cx(bigH1, H1Styles)}>Explore 2,000+ AI Resources.</h1>
      <h2 className={cx(regularText, PStyles)}>Built for Speed. Designed for Impact.</h2>
    </div>
  );
};
