import { SupportedListingEntities } from 'modules/Listings/types';
import { ComponentType } from 'react';

export enum PredictableModelType {
  VISUAL_CLASSIFIER = 'visual-classifier',
  VISUAL_DETECTOR = 'visual-detector',
  LANDMARKS = 'landmarks',
  VISUAL_SEGMENTER_SEMANTIC = 'visual-segmenter-semantic',
  VISUAL_EMBEDDER = 'visual-embedder',
  IMAGE_COLOR_RECOGNIZER = 'image-color-recognizer',
  BARCODE_OPERATOR = 'barcode-operator',
  IMAGE_TO_TEXT = 'image-to-text',
  CENTROID_TRACKER = 'centroid-tracker',
  KALMAN_FILTER_TRACKER = 'kalman-filter-tracker',
  TEXT_CLASSIFIER = 'text-classifier',
  TEXT_EMBEDDER = 'text-embedder',
  TEXT_TO_TEXT = 'text-to-text',
  AUDIO_EMBEDDER = 'audio-embedder',
  AUDIO_TO_TEXT = 'audio-to-text',
  EMBEDDING_CLASSIFIER = 'embedding-classifier',
  KNN_CONCEPT = 'knn-concept',
  CLUSTERER = 'clusterer',
  VISUAL_DETECTOR_EMBEDDER = 'visual-detector-embedder',
  OPTICAL_CHARACTER_RECOGNIZER = 'optical-character-recognizer',
  TEXT_TOKEN_CLASSIFIER = 'text-token-classifier',
  VISUAL_SEGMENTER = 'visual-segmenter',
  KALMAN_REID_TRACKER = 'kalman-reid-tracker',
  TEXT_TO_IMAGE = 'text-to-image',
  IMAGE_TO_IMAGE = 'image-to-image',
  MULTIMODAL_TO_TEXT = 'multimodal-to-text',
  MULTIMODAL_EMBEDDER = 'multimodal-embedder',
  TEXT_TO_AUDIO = 'text-to-audio',
}

export const predictableModelTypes = Object.values(PredictableModelType);

export type FeaturedModel = {
  id: string;
  name: string;
  created_at: string;
  check_consents: string[];
  modified_at: string;
  description: string;
  app_id: string;
  user_id: string;
  model_type_id: PredictableModelType;
  visibility: {
    gettable: number;
  };
  metadata: {
    presetInputs?: Array<{
      type: string;
      url: string;
    }>;
  };
  presets: Record<string, unknown>;
  is_starred: boolean;
  star_count: number;
  workflow_recommended: boolean;
  image?: {
    url: string;
    hosted?: {
      crossorigin: string;
      sizes: string[];
      prefix: string;
      suffix: string;
    };
  };
  iconComponent?: ComponentType<{ size: number }>;
} & SupportedListingEntities;
