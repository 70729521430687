import { NewHeading } from 'modules/Featured/components/Heading/NewHeading';
import { NewSearch } from 'modules/Featured/components/Search/NewSearch';
import { headerWrapper, headerAnimatedBackground } from './NewHeader.styles';

export const NewHeader: React.FC = (): JSX.Element => (
  <div className={headerWrapper}>
    <div className={headerAnimatedBackground} style={{ backgroundImage: `url('/new-bg.gif')` }} />
    <NewHeading />
    <NewSearch />
  </div>
);
