/* eslint-disable @typescript-eslint/no-explicit-any */
import { either } from 'fp-ts';
import { pipe } from 'fp-ts/lib/function';
import { FC } from 'react';
import { reactLeftToJSX, UI_STATES } from 'utils/uiStates/uiStates';
import { queryKeys, useQueryTE } from 'utils/react-query';
import { errorToReactLeft } from 'utils/fp';
import { modelsMST } from 'modules/Models/listingStore/mst-types';
import { makeModelsUrl } from 'modules/Models/listingStore/createModelsList';
import { List } from 'components/List';
import { getIsEntityOwner } from 'modules/Auth/hooks';
import { workflowListMST } from 'modules/Workflows/listingStore/mst-types';
import { makeWorkflowUrl } from 'modules/Workflows/listingStore/createWorkflowsList';
// import { moduleListMST } from 'modules/Modules/listingStore/mst-types';
// import { makeModuleUrl } from 'modules/Modules/listingStore/createModulesList';
import { useRouter } from 'next/router';
import { getExplorePageNameByPath } from 'utils/urls/pageName';
import { minusMargin } from 'components/List/List.styled';
import { SectionWithTitle } from '../SectionWithTitle/SectionWithTitle';
import { wrapper } from './FeaturedItems.styled';
import { featuredApiEffects } from './apiEffects';

/* TT-2689: TODO support featured_only query in API for modules then enable/remove this flag */
// const showFeaturedModules = false;

export const Featured: FC = () => {
  const {
    data: featuredWorkflows
  } = useQueryTE([queryKeys.Workflows, {
    featured: true
  }], featuredApiEffects.listFeaturedWorkflowsTE());
  const {
    data: featuredModels
  } = useQueryTE([queryKeys.Models, {
    featured: true
  }], featuredApiEffects.listFeaturedModelsTE());
  // const { data: featuredModules } = useQueryTE([queryKeys.Modules, { featured: true }], featuredApiEffects.listFeaturedModulesTE());

  const router = useRouter();
  return <div className={wrapper}>
      <SectionWithTitle title="Featured Models" link={{
      title: 'Explore all models',
      url: '/explore/models'
    }}>
        {pipe(featuredModels, either.mapLeft(_ => ({
        type: UI_STATES.empty
      })), either.fold(reactLeftToJSX(), featuredModelsList => {
        const models = modelsMST.create(featuredModelsList);
        return <div className={minusMargin}>
                <List list={models} makeUrl={makeModelsUrl} pageName={getExplorePageNameByPath(router.pathname) || ''} getIsEntityOwner={getIsEntityOwner} showFeatured />
              </div>;
      }))}
      </SectionWithTitle>
      <SectionWithTitle title="Featured Workflows" link={{
      title: 'Explore all workflows',
      url: '/explore/workflows'
    }}>
        {pipe(featuredWorkflows, either.mapLeft(_ => ({
        type: UI_STATES.empty
      })), either.fold(reactLeftToJSX(), featuredWorkflowsList => {
        if (featuredWorkflowsList.length === 0) return null;
        const wfs = workflowListMST.create(featuredWorkflowsList);
        return <div className={minusMargin}>
                <List list={wfs} makeUrl={makeWorkflowUrl} pageName={getExplorePageNameByPath(router.pathname) || ''} getIsEntityOwner={getIsEntityOwner} showFeatured />
              </div>;
      }))}
      </SectionWithTitle>
      {/* showFeaturedModules && (
        <SectionWithTitle title="Featured Modules" link={{ title: 'Explore all modules', url: '/explore/modules' }}>
          {pipe(
            featuredModules,
            either.mapLeft((_) => ({ type: UI_STATES.empty })),
            either.fold(reactLeftToJSX(), (featuredModulesList) => {
              if (featuredModulesList.length === 0) return null;
               const wfs = moduleListMST.create(featuredModulesList);
              return (
                <div className={minusMargin}>
                  <List
                    list={wfs}
                    makeUrl={makeModuleUrl}
                    pageName={getExplorePageNameByPath(router.pathname) || ''}
                    getIsEntityOwner={getIsEntityOwner}
                    showFeatured
                  />
                </div>
              );
            }),
          )}
        </SectionWithTitle>
       ) */}
    </div>;
};
export const testable = {
  apiEffects: featuredApiEffects,
  onError: errorToReactLeft
};