import { css } from '@linaria/core';
import { breakpoints, flex, theming } from 'styles/utils';
export const wrapper = `${"odules__Featured__components__Search__NewSearch_styles_ts-wrapper-w1k4p30n"}
  ${flex.init}
  ${flex.direction.column}
`;
export const inputWrapper = "odules__Featured__components__Search__NewSearch_styles_ts-inputWrapper-i1qjhpia";
export const input = "odules__Featured__components__Search__NewSearch_styles_ts-input-i1ye1m25";
export const button = "odules__Featured__components__Search__NewSearch_styles_ts-button-b18z9bek";
export const popularSearches = "odules__Featured__components__Search__NewSearch_styles_ts-popularSearches-p1d04uqs";

require("./NewSearch.styles.linaria.module.css!=!../../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js!./NewSearch.styles.ts");